import { FeatureAppLoader } from '@feature-hub/react';
import React from 'react';
import { Helmet, HelmetData, HelmetProvider } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';
import { Footer } from './components/footer';
import { Header } from './components/header';
import favicon from './static/favicon.ico';
import './static/fonts.css';
import './static/globals.css';
import { isError } from './utils/errors';
import { ErrorMessage } from './components/error-message';
import { ContentContainer } from './components/content-container';

type FeatureAppRouteProps = {
	baseUrl?: string;
	id: string;
	fileName: string;
	config?: Record<string, string | undefined>;
	webpackDevServerOn?: boolean; // refer to webpack-html-config and index-template.html
	styleLoaderOn?: boolean; // refer to webpack-html-config and index-template.html
};

const PreloadFonts = () => {
	const preloadFonts = new Set([
		'https://assets.adac.de/raw/upload/fonts/MiloADAC-bold.woff2',
		'https://assets.adac.de/raw/upload/fonts/MiloADAC-italic.woff2',
		'https://assets.adac.de/raw/upload/fonts/MiloADAC-medium.woff2',
		'https://assets.adac.de/raw/upload/fonts/MiloADAC.woff2',
		'https://assets.adac.de/raw/upload/fonts/MiloSlabADAC.woff2',
	]);

	return [...preloadFonts].map((url: string) => (
		<link key={`font_${url}`} rel="preload" as="font" type="font/woff2" href={url} crossOrigin="anonymous" />
	));
};

const FeatureAppRoute: React.FC<FeatureAppRouteProps> = ({
	baseUrl,
	id,
	fileName,
	config,
	webpackDevServerOn,
	styleLoaderOn,
}) => {
	const ssrBaseUrl = baseUrl ? `/apps/${fileName}.ssr.js` : undefined;
	return (
		<FeatureAppLoader
			baseUrl={baseUrl}
			featureAppId={`adac:feature-app:${id}`}
			src={`/apps/${fileName}.client.js`}
			serverSrc={!webpackDevServerOn ? ssrBaseUrl : undefined}
			css={!styleLoaderOn ? [{ href: '/apps/styles.css' }] : undefined}
			config={config}>
			{({ error, loading, featureAppNode }) => {
				if (isError(error)) {
					// eslint-disable-next-line no-console
					console.error(`Error: ${error.message}`);
					return (
						<ContentContainer>
							<ErrorMessage
								headline="Das Mautportal kann zur Zeit nicht geladen werden."
								subline="Bitte versuchen Sie ein Browser refresh oder versuchen Sie es später noch mal."
							/>
						</ContentContainer>
					);
				}

				return (
					<>
						<ContentContainer>{featureAppNode}</ContentContainer>
						{loading && <ContentContainer>loading..</ContentContainer>}
					</>
				);
			}}
		</FeatureAppLoader>
	);
};

const ContentWrapper: React.FC<{
	helmetContext?: HelmetData['context'];
	children?: React.ReactNode;
}> = ({ helmetContext, children }) => (
	<HelmetProvider context={helmetContext}>
		<Helmet>
			<title>Toll Portal Feature App | ADAC</title>
			{PreloadFonts()}
			<link rel="shortcut icon" type="image/x-icon" href={favicon} />{' '}
		</Helmet>
		<Header />
		{children}
		<Footer />
	</HelmetProvider>
);

export const App: React.FC<{
	helmetContext?: HelmetData['context'];
	baseUrl?: string;
	environment?: string;
	webpackDevServerOn?: boolean;
	styleLoaderOn?: boolean;
}> = ({ helmetContext, baseUrl, environment, webpackDevServerOn, styleLoaderOn }) => (
	<ContentWrapper helmetContext={helmetContext}>
		<Routes>
			<Route
				path="/*"
				element={
					<FeatureAppRoute
						baseUrl={baseUrl}
						fileName="toll-portal"
						id="toll-portal"
						config={{ environment }}
						webpackDevServerOn={webpackDevServerOn}
						styleLoaderOn={styleLoaderOn}
					/>
				}
			/>
		</Routes>
	</ContentWrapper>
);
export const ErrorPage: React.FC<{ helmetContext?: HelmetData['context']; error: Error }> = ({
	helmetContext,
	error,
}) => <ContentWrapper helmetContext={helmetContext}>Error: {error.message}</ContentWrapper>;
