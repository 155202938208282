import React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { FeatureHubContextProvider } from '@feature-hub/react';
import { ApolloProvider } from '@apollo/client';
import { App } from './app';
import { createFeatureHubClientIntegration } from './utils/create-integrator';
import type { PortalWindow } from './types';
import { apolloClient } from './apollo-client';

declare const window: PortalWindow;

type FeatureAppModuleSource = {
	readonly url: string;
	readonly moduleType?: string;
};

const getUrlsForHydrationFromDom = (): FeatureAppModuleSource[] => {
	const scriptElement = document.querySelector('script[type="x-feature-hub/urls-for-hydration"]');

	if (!scriptElement || !scriptElement.textContent) {
		return [];
	}

	return JSON.parse(scriptElement.textContent);
};

(async () => {
	const baseUrl = window.__BASE_URL__;
	const environment = window.__ENV__;
	const webpackDevServerOn = window.__WEBPACK_DEV_SERVER__ ?? false;
	const styleLoaderOn = window.__STYLE_LOADER_ON__ ?? false;
	const contentfulSpaceId = process.env.CONTENTFUL_SPACE_ID ?? '';
	const contentfulEnvironment = process.env.CONTENTFUL_ENVIRONMENT ?? '';

	const client = apolloClient({
		uri: `https://graphql.contentful.com/content/v1/spaces/${contentfulSpaceId}/environments/${contentfulEnvironment}`,
		isBrowser: true,
	});

	const container = document.querySelector('#toll-portal');
	const { featureAppManager } = createFeatureHubClientIntegration();
	const urlsForHydration = getUrlsForHydrationFromDom();

	await Promise.all(
		urlsForHydration.map(async ({ url, moduleType }) => featureAppManager.preloadFeatureApp(url, moduleType)),
	);

	if (container) {
		const root = createRoot(container);

		const app = (
			<React.StrictMode>
				<ApolloProvider client={client}>
					<FeatureHubContextProvider value={{ featureAppManager }}>
						<BrowserRouter>
							<App
								baseUrl={baseUrl}
								environment={environment}
								webpackDevServerOn={webpackDevServerOn}
								styleLoaderOn={styleLoaderOn}
							/>
						</BrowserRouter>
					</FeatureHubContextProvider>
				</ApolloProvider>
			</React.StrictMode>
		);
		// Webpack HMR and hydrate don't work well together.
		if (webpackDevServerOn) {
			root.render(app);
		} else {
			hydrateRoot(container, app);
		}
	}
	// eslint-disable-next-line no-console
})().catch(console.error);
