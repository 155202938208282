import { createFeatureHub } from '@feature-hub/core';
import { createCommonJsModuleLoader } from '@feature-hub/module-loader-commonjs';
import { AsyncSsrManagerV1, defineAsyncSsrManager } from '@feature-hub/async-ssr-manager';
import { defineServerRequest } from '@feature-hub/server-request';
import { serializedStateManagerDefinition, SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { Css, FeatureHubContextProviderValue } from '@feature-hub/react';
import { Request } from 'express';
import { loadFederatedModule } from '@feature-hub/module-loader-federation';
import type { StaticRouterProps } from 'react-router-dom/server';
import { parse } from 'url';
import { externals } from '../externals';
import type { PortalWindow } from '../types';
import { getLogLevel, Logger, LogLevel } from './logger';

type FeatureAppModuleSource = {
	readonly url: string;
	readonly moduleType?: string;
};

declare const window: PortalWindow;

const integratorId = 'adac:portal';

const getSerializedStatesFromDom = (): string | undefined => {
	const scriptElement = document.querySelector('script[type="x-feature-hub/serialized-states"]');

	return (scriptElement && scriptElement.textContent) || undefined;
};

const cookieToObject = (cookie: string): Record<string, string> =>
	cookie
		.split(';')
		.filter((c) => c.length)
		.reduce((acc, c) => {
			const [key, value] = c.split('=');

			return {
				...acc,
				[key.trim()]: value.trim(),
			};
		}, {});

export const createFeatureHubSSRIntegration = (event: Request) => {
	const logger = new Logger({
		logLevelBoundary: process.env.LOG_LEVEL ? getLogLevel(process.env.LOG_LEVEL) : LogLevel.WARN,
	});

	const asyncSsrManagerDefinition = defineAsyncSsrManager({ timeout: 25000 });

	const searchParameters = parse(event.url).search ?? '';
	const url = `${event.path}?${searchParameters ?? ''}`;
	const pathname = parse(event.url).pathname ?? '/';

	const serverRequestDefinition = defineServerRequest({
		url,
		cookies: cookieToObject(event.headers.cookie ?? ''),
		headers: event.headers,
	});

	const location: StaticRouterProps['location'] = {
		pathname,
		search: searchParameters,
	};

	const { featureAppManager, featureServices } = createFeatureHub(integratorId, {
		featureServiceDefinitions: [
			asyncSsrManagerDefinition,
			serializedStateManagerDefinition,
			serverRequestDefinition,
		],
		featureServiceDependencies: {
			[asyncSsrManagerDefinition.id]: '^1.0.0',
			[serializedStateManagerDefinition.id]: '^1.0.0',
			[serverRequestDefinition.id]: '^1.0.0',
		},
		logger,
		moduleLoader: createCommonJsModuleLoader(externals),
	});

	const asyncSsrManager = featureServices[asyncSsrManagerDefinition.id] as AsyncSsrManagerV1;

	const serializedStateManager = featureServices[serializedStateManagerDefinition.id] as SerializedStateManagerV1;

	const hydrationSources = new Map<string, FeatureAppModuleSource>();
	const stylesheetsForSsr = new Map<string, Css>();
	const featureHubContextValue: FeatureHubContextProviderValue = {
		featureAppManager,
		asyncSsrManager,
		addUrlForHydration: (hydrationUrl, moduleType) =>
			hydrationSources.set(`${hydrationUrl}${moduleType}`, { url: hydrationUrl, moduleType }),
		addStylesheetsForSsr: (stylesheets) => {
			// eslint-disable-next-line no-restricted-syntax
			for (const stylesheet of stylesheets) {
				stylesheetsForSsr.set(stylesheet.href, stylesheet);
			}
		},
	};

	return {
		asyncSsrManager,
		featureHubContextValue,
		hydrationSources,
		serializedStateManager,
		stylesheetsForSsr,
		location,
	};
};

export const createFeatureHubClientIntegration = () => {
	const logger = new Logger({
		logLevelBoundary: window.__LOG_LEVEL__ ? getLogLevel(window.__LOG_LEVEL__) : LogLevel.WARN,
	});

	const { featureAppManager, featureServices } = createFeatureHub(integratorId, {
		featureServiceDefinitions: [serializedStateManagerDefinition],
		featureServiceDependencies: {
			[serializedStateManagerDefinition.id]: '^1.0.0',
		},
		moduleLoader: loadFederatedModule,
		logger,
	});

	const serializedStateManager = featureServices[serializedStateManagerDefinition.id] as SerializedStateManagerV1;

	const serializedStates = getSerializedStatesFromDom();

	if (serializedStates) {
		serializedStateManager.setSerializedStates(serializedStates);
	}
	return { featureAppManager };
};
