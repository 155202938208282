/* eslint no-console: "off" */

import { serializeError } from 'serialize-error';

export enum LogLevel {
	DEBUG = 'DEBUG',
	INFO = 'INFO',
	WARN = 'WARN',
	ERROR = 'ERROR',
	TRACE = 'TRACE',
}

export const getLogLevel = (levelString: string) => {
	switch (levelString.toLowerCase()) {
		case 'debug':
			return LogLevel.DEBUG;
		case 'info':
			return LogLevel.INFO;
		case 'warn':
			return LogLevel.WARN;
		case 'error':
			return LogLevel.ERROR;
		case 'trace':
			return LogLevel.TRACE;
		default:
			throw new Error(`Unsupported log level value: ${levelString}`);
	}
};

type ConstructorParams = {
	logLevelBoundary?: LogLevel;
};

export class Logger {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	private static instance: Logger;

	public logLevelBoundary: LogLevel;

	public static Instance(params: ConstructorParams = {}) {
		if (Logger.instance === null) {
			Logger.instance = new Logger(params);
		}

		return Logger.instance;
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/explicit-member-accessibility
	constructor({ logLevelBoundary: level = LogLevel.INFO }: ConstructorParams = {}) {
		this.logLevelBoundary = level;
	}

	public raw(message: string) {
		console.log(message);
	}

	public debug(message: string, ...args: object[]) {
		this.logMessage(LogLevel.DEBUG, message, ...args);
	}

	public info(message: string, ...args: object[]) {
		this.logMessage(LogLevel.INFO, message, ...args);
	}

	public warn(message: string, ...args: object[]) {
		this.logMessage(LogLevel.WARN, message, ...args);
	}

	public error(message: string, ...args: object[]) {
		this.logMessage(LogLevel.ERROR, message, ...args);
	}

	public trace(message: string, ...args: object[]) {
		this.logMessage(LogLevel.TRACE, message, ...args);
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	private logLevelIndex(level: LogLevel) {
		switch (level) {
			case LogLevel.TRACE:
				return 1;
			case LogLevel.DEBUG:
				return 2;
			case LogLevel.INFO:
				return 3;
			case LogLevel.WARN:
				return 4;
			case LogLevel.ERROR:
				return 5;
			default:
				throw new Error(`Unsupported log level: ${level}`);
		}
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	private skipLogMessage(level: LogLevel) {
		return this.logLevelIndex(level) < this.logLevelIndex(this.logLevelBoundary);
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	private logMessage(level: LogLevel, message: string, ...args: object[]) {
		if (this.skipLogMessage(level)) {
			return;
		}

		const logObject = JSON.stringify({
			level,
			message: typeof message === 'string' ? message.trim() : message,
			timestamp: new Date().toISOString(),
			details: args.map((arg) => (arg instanceof Error ? serializeError(arg) : arg)),
		});

		switch (level) {
			case LogLevel.DEBUG:
				console.debug(logObject);
				break;
			case LogLevel.INFO:
				console.info(logObject);
				break;
			case LogLevel.WARN:
				console.warn(logObject);
				break;
			case LogLevel.ERROR:
				console.error(logObject);
				break;
			case LogLevel.TRACE:
				console.trace(logObject);
				break;
			default:
				throw Error(`log level ${level} not supported!`);
		}
	}
}

export const logger = Logger.Instance();
