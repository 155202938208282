import * as React from 'react';

type ErrorMessageProps = {
	headline: string;
	subline: string | JSX.Element;
	className?: string;
};

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ headline, subline, className }) => (
	<div className={`${className} w-full text-center mx-auto max-w-[500px]`}>
		<h3>{headline}</h3>
		<p>{subline}</p>
	</div>
);
