import React from 'react';
import Logo from '../static/assets/ADAC-Logo.svg';

export const Header: React.FC = () => (
	<>
		<header className="fixed w-full top-0 z-2999 h-7 bg-primary duration-300 md:relative">
			{/* <div className="bg-black text-white hidden lg:block">
			<div className="max-w-maxDesktop w-full font-bold py-1.5 px-2">Top Themen</div>
		</div> */}
			<div className="max-w-maxDesktop px-2 flex items-center h-full">
				<a href="/">
					<img src={Logo} width="90" alt="ADAC Logo" />
				</a>
			</div>
		</header>
		<nav className="hidden h-[42px] sticky top-0 z-50 bg-white transition ease-in-out sm:block">
			<div className="max-w-maxDesktop px-2 flex items-center h-full">
				<a href="/">Menu Item</a>
			</div>
		</nav>
	</>
);
