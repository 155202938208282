import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import fetch from 'cross-fetch';

type ApolloClientParameter = {
	contentfulAccessToken: string;
	isBrowser: boolean;
	state?: NormalizedCacheObject;
	uri: string;
};

export const apolloClient = ({ uri, state, isBrowser }: ApolloClientParameter) => {
	const cache = new InMemoryCache();

	if (state) {
		cache.restore(state);
	}

	return new ApolloClient({
		ssrMode: !isBrowser,
		connectToDevTools: isBrowser,
		link: new HttpLink({ fetch, uri }),
		cache,
		// Error policy 'none' is needed to forbid client caching, if errors are coming alongside with data,
		// cause Apollo Client caches only data. Otherwise client would be unaware of error when using cache.
		// Read more:
		// https://github.com/apollographql/apollo-client/issues/4644
		// https://github.com/apollographql/apollo-client/issues/4806
		defaultOptions: {
			watchQuery: {
				// TODO: change this to cache-and-network once we have a proper page transition
				fetchPolicy: isBrowser ? 'cache-first' : 'network-only',
				errorPolicy: 'none',
			},
			query: {
				fetchPolicy: isBrowser ? 'cache-first' : 'network-only',
				errorPolicy: 'none',
			},
		},
	});
};
